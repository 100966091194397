<template>
    <div class="container-fluid feeds">
      <div class="col-sm-12 col-md-12 feeds">
        <Loader v-if="!game"/>
        <div class="div-flex" v-if="game">
          <the-sidebar class="hidden-sm hidden-xs"/>
          <div class="feed">
            <div class="contain-overflow">
              <div class="more-match-markets">
                 <div class="single-match-details" v-if="game">
                  <div class="match-header">
                    <span class="" @click="$router.go(-1)">
                      <i class="icofont-rounded-left"></i>
                    </span>
                    <p class="league">
                      <span v-if="$i18n.locale == 'en'">{{ game.country }}, {{ game.competition }}</span>
                      <span v-if="$i18n.locale == 'fr'">{{ game.countryFr }},  {{ game.competitionFr }}</span> 
                       {{ game.tournament }}, 
                       <em class="text-orange">{{ game.status  }} {{ game.time }}'</em>
                    </p>
                    <div style="width: 10px;"></div>
                  </div>
                  <div class="match-info">
                    <div class="">
                      <span><i class="icofont-cop-badge"></i></span>
                      <span>{{ game.home }}</span>
                    </div>
                    <div class="">
                      <span>vs</span>
                      <span class="text-orange"> {{ (game.statistic.score.ft.split("-").length > 1) ? game.statistic.score.ft : `${game.statistic.score.ft.split(" ")[0]}-${game.statistic.score.ft.split(" ")[1]}` }}  </span>
                    </div>
                    <div class="">
                      <span><i class="icofont-cop-badge"></i></span>
                      <span>{{ game.away }}</span>
                    </div>
                  </div>                
                </div>
               
                 <div class="single-match-details" v-if="$route.params.sportId == 1">
                  <ul class="list-inline cat">
                    <li>
                      <span :class="{ 'select' : marketIndex == 0 }" @click="selectCat(0)">{{ $t('feeds.all-markets')}}</span>
                    </li>
                    <li>
                      <span :class="{ 'select' : marketIndex == 1 }" @click="selectCat(1)">{{ $t('feeds.main')}}</span>
                    </li>
                    <li>
                      <span :class="{ 'select' : marketIndex == 2 }" @click="selectCat(2)">{{ $t('feeds.first-half')}}</span>
                    </li>
                    <li>
                      <span :class="{ 'select' : marketIndex == 3 }" @click="selectCat(3)">{{ $t('feeds.goals')}}</span>
                    </li>
                    <li>
                      <span :class="{ 'select' : marketIndex == 4 }" @click="selectCat(4)">{{ $t('feeds.player')}}</span>
                    </li>
                    <li>
                      <span :class="{ 'select' : marketIndex == 5 }" @click="selectCat(5)">{{ $t('feeds.combo')}}</span>
                    </li>
                    <li>
                      <span :class="{ 'select' : marketIndex == 6 }" @click="selectCat(6)">{{ $t('feeds.others')}}</span>
                    </li>
                  </ul>
                </div>
                
                <div class="match-markets">
                  <div v-frag v-for="(market,value) in match" :key="'dis'+value">                                     
                    <div v-for="(mkt,i) in display_m.markets" :key="'x' + i">
                      <template v-if="(market.key == mkt.key) && market.odds.filter( x => x.status == 1 && x.odds > 0).length > 1">
                        <span class="market-title" data-toggle="collapse" aria-expanded="true" :data-target="('#demo'+market.id+value)">
                          <i class="icofont-star-shape" ></i>  
                          <span v-if="$i18n.locale == 'en'">{{ market.name }}</span>
                          <span v-if="$i18n.locale == 'fr'">{{ market.frName }}</span>
                          <i class="icofont-caret-right pull-right"></i>
                        </span>
                        <div class="collapse in" :id="('demo'+market.id+value)">
                          <div class="market-body other" :class="{ 'three-markets':mkt.selections.length > 2,'two-markets':mkt.selections.length == 2 }">
                            <div v-frag v-for="(select,x) in mkt.selections" :key="x">
                              <template v-if="match.find( x => x.key === mkt.key).odds.length > 0"> 
                                <div v-frag v-for="(odd,y) in match.find( x => x.key == mkt.key).odds" :key="y">
                                  <div 
                                    v-if="(select.id == odd.outcomeId) &&  odd.status == 1 && odd.odds > 0"
                                    @click="addToBetslip(odd,game,match.find( g => g.marketId === market.marketId && g.key === market.key))"                 
                                    :class ="{ 'selected' : odd.key == game.selected_odd_id || sharedBet.find(x => x.odd_id == odd.key) }"
                                  >
                                    <span v-if="$i18n.locale == 'en'">
                                      {{ select.name }}
                                      <i v-if="odd.up" class="fa fa-caret-up text-green" aria-hidden="true"></i>
                                      <i v-if="odd.down" class="fa fa-caret-down text-red" aria-hidden="true"></i>
                                    </span>
                                    
                                    <span class="pull-right">{{ parseFloat(odd.odds).toFixed(2) }}</span>
                                  </div>
                                  <div v-if="(select.id == odd.outcomeId) && odd.status != 1 || (select.id == odd.outcomeId) && odd.odds == 0">
                                    <span v-if="$i18n.locale == 'en'">
                                      {{ select.name }}
                                      <i v-if="odd.up" class="fa fa-caret-up text-green" aria-hidden="true"></i>
                                      <i v-if="odd.down" class="fa fa-caret-down text-red" aria-hidden="true"></i>
                                    </span>
                                    
                                    <span class="odd"><i class="icofont-lock"></i></span>
                                  </div>
                                </div>
                              </template>
                              </div>                                                                  
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                
                  <div v-frag v-for="(market,ind) in match" :key="market.id + 'dd' + ind">
                    <template v-if="marketIds.indexOf(market.key) === -1 && market.odds.length > 0 && market.odds.filter( x => x.status == 1 && x.odds > 0).length > 1">
                      <span class="market-title" data-toggle="collapse" aria-expanded="false" :data-target="('#demo'+market.id+'h'+ind)">
                        <i class="icofont-star-shape"></i> 
               
                        <span v-if="$i18n.locale == 'en'">{{ market.name }}</span> 
                        <span v-if="$i18n.locale == 'fr'">{{ market.frName }}</span>
                        <i class="icofont-caret-right pull-right"></i>
                      </span>
                      <div class="collapse in" :id="('demo'+market.id+'h'+ind)" >
                        <div class="market-body other" :class="{ 'three-markets':market.odds.filter( x => x.status == 1 && x.odds > 0).length > 2,'two-markets':market.odds.filter( x => x.status == 1 && x.odds > 0).length == 2 }">
                          <div v-frag v-for ="(odd,x) of market.odds" :key="x+'uu'">
                            <div v-if="  odd.status == 1 && odd.odds > 0" @click="addToBetslip(odd,game,market)" :class ="{ 'selected' : odd.key == game.selected_odd_id || sharedBet.find(x => x.odd_id == odd.key) }">
                              <span v-if="$i18n.locale == 'en'">
                                {{ odd.name }}
                                <i v-if="odd.up" class="fa fa-caret-up text-green" aria-hidden="true"></i>
                                <i v-if="odd.down" class="fa fa-caret-down text-red" aria-hidden="true"></i>
                              </span>
                              <span v-if="$i18n.locale == 'fr'">
                                {{ odd.frName }}
                                <i v-if="odd.up" class="fa fa-caret-up text-green" aria-hidden="true"></i>
                                <i v-if="odd.down" class="fa fa-caret-down text-red" aria-hidden="true"></i>
                              </span>
                              <span class="pull-right">{{ parseFloat(odd.odds).toFixed(2) }}</span>
                            </div>
                            <!-- <div v-if="odd.status != 1 || odd.odds == 0">
                              <span v-if="$i18n.locale == 'en'">
                                  {{ odd.name }}
                                  <i v-if="odd.up" class="fa fa-caret-up text-green" aria-hidden="true"></i>
                                  <i v-if="odd.down" class="fa fa-caret-down text-red" aria-hidden="true"></i>
                                </span>
                                <span v-if="$i18n.locale == 'fr'">
                                  {{ odd.frName }}
                                  <i v-if="odd.up" class="fa fa-caret-up text-green" aria-hidden="true"></i>
                                  <i v-if="odd.down" class="fa fa-caret-down text-red" aria-hidden="true"></i>
                                </span>
                              <span class="odd"><i class="icofont-lock"></i></span>
                            </div> -->
                            
                          </div>            
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <the-betslip class="hidden-sm hidden-xs home-bet"/>
        </div>
      </div>
    </div>
  </template>
  <script>
  import SocketioService from "@/services/socket";
  import {mapGetters} from "vuex";
  import BetslipService from "@/services/betslip";
  import Loader from "@/components/sections/loader.vue";
  
  export default {
    name: 'single-match',
    data() {
      return {
        specifier: [],
        display: [1,10],
        main:[1,10,18,47,29,30,36,45,551,861],
        first_half:[1179,149,150,151,152,155,173,174,176,177,552,60,62,63,64,68,74,78,79,81],
        goals:[21,548,552,553,820,549,550],
        player:[38,39,40,888,889,890,891],
        combo:[1179,540,541,543,544,545,546,547,818,819,820,83,84,85,90,94,95],
        others:[14,176,26,74,94,172],
        load: false,
        marketIndex:0,
        interval:null
      }
    },
    computed:{
      ...mapGetters({
        game: 'singleLive',
        live_markets: 'live_markets',
        sharedBet:'sharedBet',
      }),
      
      display_m() {

          return this.live_markets.find( x=> x.sportId == this.$route.params.sportId);
      },
      marketIds() {
        let temp = [];
        this.display_m.markets.map( x => temp.push(x.key))
        return temp;
      },
      match() {   
        let temp;
        switch (this.marketIndex) {
          case 0:
            temp = this.game.markets;
            break;
          case 1:
            temp = (this.game) ? this.game.markets.filter( x => this.main.includes(Number(x.id)) ) : [];
            
            break;
          case 2:
            temp = (this.game) ? this.game.markets.filter( x => this.first_half.includes(Number(x.id)) ) : [];
            break;
          case 3:
            temp = (this.game) ? this.game.markets.filter( x => this.goals.includes(Number(x.id)) ) : [];
            break;
          case 4:
            temp = (this.game) ? this.game.markets.filter( x => this.player.includes(Number(x.id)) ) : [];
            break;
          case 5:
            temp = (this.game) ? this.game.markets.filter( x => this.combo.includes(Number(x.id)) ) : [];
            break;
          case 6:
            temp = (this.game) ? this.game.markets.filter( x => this.others.includes(Number(x.id)) ) : [];
            break;
          default:
            break;
        }
        return temp;
      }
    },
    components: {
      Loader,
      // odd
    },
    methods: { 
      addToBetslip: function(odds, match, market) {  
        let obj= {
          id:match.id,
          home: match.home,
          away:match.away,
          sportId: match.sportId,
          scheduled: match.scheduled,
  
          market: {
              id:market.marketId,
              mid:market.id,
              mhandicap: market.handicap,
              mname: market.name,
              frname: market.frName,
              oddId: odds.key,
              outcome: odds.outcomeId,
              pick: odds.name,
              frpick: odds.frName,
              status: odds.status,
              odd: odds.odds,
              key: market.key,
              oddKey:odds.key_,
              type: 'live'
          }
        } 
  
        let check = this.sharedBet.find(x => x.match_id == match.id);
        if(check)
        {
          this.$store.dispatch('removeSharedBets',match.id);
        }
        // add bet to slip
        BetslipService.addBetslip(obj);
      },
     
      thisMounted: async function() {
        this.load = true;
        this.$Progress.start();
        await this.$store.dispatch("get_markets",{
          id:this.$route.params.sportId
        })

        await this.$store.dispatch("get_live_match",this.$route.params.id)
      
        let obj = {sport: this.$route.params.id, room: this.room,id: this.$route.params.sportId}
        SocketioService.changeSChannel(obj);
        SocketioService.getSingleLiveGames();

        if(this.game) {
          let p = [this.game.id]
          this.slipsCheck(p)
          
          this.interval = setInterval(() => {
            this.slipsCheck(p);
          }, 8000);
        }
        
        this.$Progress.finish();
      },
      
      selectCat(i) {
        this.marketIndex = i;
      },
      slipsCheck: async function(data) {
        await this.$store.dispatch("fetch_live_slips",data);
      }
    },
    created () {

      this.subscription = BetslipService.getAddedBetslip().subscribe( () => {
        this.$store.dispatch("modify_live_state");
      });
  
      this.mysubscription = BetslipService.getRemovedBetslip().subscribe( () => { 
        this.$store.dispatch("modify_live_state");
      });
    },
    mounted(){
      this.thisMounted()  
    },
    beforeDestroy () {
      clearInterval(this.interval);
      SocketioService.leaveSRoom() 
      
    } 
  }
  </script>
  <style scoped>
    .feeds {
      padding: 4px;
    }
    .div-flex {
      display: flex;
    }
    .div-flex .feed {
      width: 100%;
    }
    .div {
      cursor: pointer;
    }
    div span:nth-child(2){
      font-weight: normal;
    }
    .cat {
      overflow-x: scroll;
      white-space: nowrap;
    }
    .cat span {
      display: inline-block;
      background-color: var(--bg-blue);
      padding: 4px 10px;
      border-radius: 15px;
      cursor: pointer;
    }
    .cat span.select {
      background-color: var(--bg-orange);
    }
    .text-green{
      color: teal;
    }
    .text-red {
      color: red;
    }
  </style>